import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import ImpactOfCoronaImageSource from 'images/impact-of-the-coronavirus.jpg';
import ImpactOfCoronaThumbImageSource from 'images/featured-image/impact-of-the-coronavirus.jpg';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import OptinBlog from 'components/pages/OptinBlog';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, BottomShare, SectionFull, OptinSection} from 'components/blog/SingleBlogStyle';


interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query ImgBlog3Query {
      file(relativePath: { eq: "impact-of-the-coronavirus-banner.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="jumping man in the cargo container"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Impact of the Coronavirus on Global Supply Chains | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-impact-of-the-coronavirus" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "Covid-19 or Coronavirus is impacting the global supply chains but how is Shipkoo making it possible?",
            },
            {
              property: `og:title`,
              // content: "Impact of the Coronavirus on Global Supply Chains | Shipkoo",
              content: intl.formatMessage({ id: "blog-post-meta-title-impact-of-the-coronavirus" }),
            },
            {
              property: `og:description`,
              content: "Covid-19 or Coronavirus is impacting the global supply chains but how is Shipkoo making it possible?",
            },
            {
              property: `og:image`,
              content: ImpactOfCoronaImageSource,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },

          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="the-impact-of-the-coronavirus-on-global-supply-chains"
        title="The Impact of the Coronavirus on Global Supply Chains"
        date="2020-04-10"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              The novel coronavirus, also named CoVid-19, has been dominating the world’s attention for the past three months and has since been qualified as a pandemic. Unfortunately, it has also been challenging the global supply chains, due to tremendous shifts in demand and countries and corporations imposing restrictions with the goal of mitigating the spread of the virus.
            </p>

            <h5>How sourcing is affected</h5>
            
            <p>
              One of the main problems we have been facing is that many of the world’s products are manufactured in China. When the danger of the coronavirus became clearer the Chinese government quickly closed many of the countries’ manufacturing facilities, creating a problem for companies across the globe that source their products in China. At first, this moved some companies to search for new sourcing locations. However, currently China seems to be the first country to have the situation largely under control and is reopening the majority of its factories. Now that most of the rest of the world is struggling to control the virus it seems to be the best move to stay in China or possibly even move production to China if sourcing is currently done elsewhere.
            </p>

            <Image src={ImpactOfCoronaImageSource} alt="infected lungs of corona virus" />
            
            <h5>The impact on supply chains between the East and the West</h5>

            <p>
              While the East has been struggling to maintain the same level of production, it has become increasingly hard to live up to the demand from the West. Many popular Western brands’ have parts of their supply chains running through Asian countries and are struggling due to restrictions imposed by their own or by foreign governments. Unfortunately, this is nevertheless combined with an increase in online shopping due to people being stuck at home. Moreover, the demand for medical supplies is surging in the West now that Europe and the US can be seen as the epicentres of the pandemic. Many international logistics providers have been affected due to a reduction in air freight, slowing down shipments across the globe. Since passenger flights have been reduced dramatically, some passenger planes are now used for cargo instead in order to live up to the increased demand for many products. In many parts of the global supply chain we find struggles, be it a scarcity in truck drivers or forced reroutes due to government-imposed restrictions. It is therefore of vital importance that we keep working together to maintain the supply chains and retain the connections between the East and West.
            </p>
            
            <h5>Shipkoo and the coronavirus</h5>

            <p>
              At Shipkoo we are highly determined to do everything within our power to keep the East and West connected. We are providing reliable sourcing solutions in China for many different products, including medical supplies like masks and thermometers. Our shipping solutions are focused on getting products sourced in the East as fast as possible to end consumers in the West. Thanks to our collaborations with many different logistics providers we are able to currently maintain a steady flow and continue to make customers happy around the globe.
            </p>
            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare>
          </div>
          <Clear></Clear>  
        </BlogRow>
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;

